'use strict';

/**
 *	NodeList.forEach()
 *
 *  Polyfill for browsers that don't have native feature support for NodeList.forEach().
 *
 *	@author: Mozilla
 *	@documentation: https://developer.mozilla.org/en-US/docs/Web/API/NodeList/forEach
 */
	if(typeof NodeList.prototype.forEach !== "function")
	{
		NodeList.prototype.forEach = Array.prototype.forEach;
	}