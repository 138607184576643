'use strict';

{
	/* let regionId = 'products';

	window.do.when(`#${regionId}`).then(region => {
		let productsSelector = `${window.namespace}-${regionId}`;
		let selector = `${productsSelector}__card`;	
		let cards = region.querySelectorAll(`.${selector}`);

		cards.forEach(card => {
			[
				'mouseenter',
				'focus'
			].forEach(eventListener => {
				card.addEventListener(eventListener, event => {
					let context = event.target;
					let parent = context.closest(`.${productsSelector}`);

					context.classList.add(`${selector}--focus`);
					parent.classList.add(`${productsSelector}--focus-within`);
				});
			});

			[
				'mouseleave',
				'blur'
			].forEach(eventListener => {
				card.addEventListener(eventListener, event => {
					let context = event.target;
					let parent = context.closest(`.${productsSelector}`);

					context.classList.remove(`${selector}--focus`);
					parent.classList.remove(`${productsSelector}--focus-within`);
				});
			});

			[
				'mousemove'
			].forEach(eventListener => {
				card.addEventListener(eventListener, event => {
					let context = event.target.closest(`.${selector}`);
					let parent = context.closest(`.${productsSelector}`);
					
					cards.forEach(x => {
						if(context === x)
						{
							x.classList.add(`${selector}--focus`);
						}
						
						else
						{
							x.classList.remove(`${selector}--focus`);
						}
					});
					
					if(parent.querySelector(`.${selector}--focus`))
					{
						parent.classList.add(`${productsSelector}--focus-within`);
					}
					
					else
					{
						parent.classList.remove(`${productsSelector}--focus-within`);
					}
				});
			});
		});
	}); */
}