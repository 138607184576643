'use strict';

/**
 *	Window.scrollX | Window.scrollY
 *
 *  Polyfill for browsers that don't have native feature support for Window.scrollX | Window.scrollY.
 *
 *	@author: Mozilla
 *	@documentation: https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollX
 */
	if(Window && (!Window.prototype.scrollY || !Window.prototype.scrollX))
	{
		Window.prototype.scrollPolyfill = true;

		if(Window && !Window.prototype.scrollY) window.scrollY = window.pageYOffset || document.documentElement.scrollTop;
		if(Window && !Window.prototype.scrollX) window.scrollX = window.pageXOffset || document.documentElement.scrollLeft;
	}

	document.addEventListener("scroll", function(event)
	{
		if(Window && Window.prototype.scrollPolyfill)
		{
			window.scrollY = window.pageYOffset || document.documentElement.scrollTop;
			window.scrollX = window.pageXOffset || document.documentElement.scrollLeft;
		}
	});