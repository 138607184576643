'use strict';

{
	let regionId = 'contact';
	
	{
		window.do.when(`#${regionId}`).then(region => {
			let selector = `${window.namespace}-section__content`;
			let content = region.querySelector(`.${selector}[id]`).getBoundingClientRect();

			region.setHeight = function(to)
			{
				if(typeof to === 'number')
				{
					region.style.height = `${to}px`;
					
					return;
				}

				if(typeof to === 'object' && (to instanceof Element || to instanceof HTMLDocument))
				{
					region.style.height = `${to.getBoundingClientRect().height}px`;

					return;
				}
				
				else if(typeof to === 'string')
				{
					to = region.getElementById(`${to}`) || region.querySelector(`${to}`);
					
					region.style.height = `${to.getBoundingClientRect().height}px`;

					return;
				}
				
				console.error('region.setHeight: supplied argument not valid.');

				return;
			};
			
			region.setHeight(region.getBoundingClientRect().height);

			region.bindView = function(req,context)
			{
				let selector = `${window.namespace}-section__content`;

				region.displays = region.querySelectorAll(`.${selector}`);

				region.displays.forEach(display => {
					display.transitioner = window.set.transitioner({
						displayValue: 'block',
						element: display,
						hideMode: 'hidden',
						visibleClass: `${selector}--visible`
					}).then(context => {
						display.transitioner = context;
					});
				});

				window.do.when(region.querySelector(`.${selector}`).transitioner).then(ctx => {
					region.updateDisplay = region.updateDisplay || function(id = 'intro')
					{
						id = `${regionId}-${id}`;
						let is = region.querySelector(`.${selector}--visible[id]`).id;

						if(id === is)
						{
							return;
						}

						let display = region.querySelector(`.${selector}[id='${id}']`);

						region.querySelectorAll(`.${selector}[id]`).forEach(display => {
							display.setAttribute('aria-hidden', 'true');
							display.transitioner.hide();
						});

						window.setTimeout(() => {
							let display = region.querySelector(`.${selector}[id='${id}']`);
							
							window.scrollToElement(region);

							display.removeAttribute('aria-hidden');
							display.transitioner.show();
							
							region.setHeight(display.getBoundingClientRect().height);
						}, 550);
					};

					region.updateDisplay(window.router.req.params.display);
				});
			}
		});
	}

	window.router
		.always(context => {
			window.do.when(`#${regionId}`).then(region => {
				let selector = `${window.namespace}-section__content--visible`;

				window.do.when(region.querySelector(`.${selector}`)).then(content => {
					region.setHeight(content);
				});
			});
		})
		.get(`/${regionId}`, (req, context) => {
			window.router.req = req;
			window.router.context = context;

			window.do.when(`#${regionId}`).then(region => {
				region.bindView(req,context);
			});
		})
		.get(`/${regionId}/:display`, (req, context) => {
			window.router.req = req;
			window.router.context = context;

			window.do.when(`#${regionId}`).then(region => {
				region.bindView(req,context);
			});
		})
}