'use strict';

/**
 *	Element.closest()
 *
 *  Polyfill for browsers that don't have native feature support for Element.closest().
 *
 *	@author: Mozilla
 *	@documentation: https://developer.mozilla.org/en-US/docs/Web/API/Element/closest
 */
	if(!Element.prototype.closest) Element.prototype.closest = function(s)
	{
		var el = this;

		do
		{
			if(el.matches(s)) return el;

			el = el.parentElement || el.parentNode;
		}

		while(el !== null && el.nodeType === 1);

		return null;
	};