'use strict';

{
	let setSiteOverflow = function(trigger = null)
	{
		trigger = trigger || document.getElementById('#navigation-trigger');
		
		if(trigger)
		{
			let site = [
				document.documentElement,
				document.body
			];

			site.forEach(context => {
				context.style.overflowY = (trigger.checked) ? 'hidden' : 'auto';
			});
		}
	};
	
	window.do.when('#navigation').then(navigation => {
		navigation.trigger = null;

		window.do.when('#navigation-trigger').then(trigger => {
			navigation.trigger = trigger;

			navigation.querySelectorAll(`.${window.namespace}-nav__menu-item[href]`).forEach(item => {
				let href = item.getAttribute('href');

				item.setAttribute('href', `${href}${location.search}`);
				
				item.addEventListener('click', click => {
					navigation.trigger.checked = false;
				});
			});
		});
	});

	init_menu_fx:
	{
		window.do.when('#navigation').then(nav => {
			let selector = `${window.namespace}-nav__menu`;
			
			let menus = nav.querySelectorAll(`.${selector}`);

			menus.forEach(menu => {
				menu.querySelectorAll(`.${selector}-group`).forEach(group => {
					let fx = group.querySelector(`.${selector}-group-fx`);
					
					if(fx)
					{
						let label = group.querySelector(`[href] .${selector}-item-label`);
						let pos = {
							left: label.getBoundingClientRect().left - group.getBoundingClientRect().left,
							right: Math.floor((group.getBoundingClientRect().right - label.getBoundingClientRect().right))
						};

						fx.style.left = `${pos.left}px`;
						fx.style.right = `${pos.right}px`;
						fx.dataset.ready = true;
						
						fx.addEventListener('transitionend', transition => {
							fx.style.opacity = (fx.getBoundingClientRect().width < 1.9) ? 0 : 1;
						});

						document.addEventListener('scroll', scroll => {
							if(window.scrollDirection)
							{
								fx.dataset.motion = (window.scrollDirection === 'down') ? 'right' : 'left';
							}
						});
					}
				});
			});
		});
	}
	
	window.addEventListener('regionchange', event => {
		window.do.when('#navigation').then(nav => {
			let selector = `${window.namespace}-nav__menu`;
			let menus = nav.querySelectorAll(`.${selector}`);

			menus.forEach(menu => {
				menu.querySelectorAll(`.${selector}-group`).forEach(group => {
					let fx = group.querySelector(`.${selector}-group-fx`);
					
					if(fx)
					{
						let labels = group.querySelectorAll(`[href] .${selector}-item-label`);
						let label = group.querySelector(`[href$='${event.regionId}'] .${selector}-item-label`);
						let pos;

						if(label)
						{
							pos = {
								left: label.getBoundingClientRect().left - group.getBoundingClientRect().left,
								right: Math.floor(group.getBoundingClientRect().right - label.getBoundingClientRect().right)
							};
						}
						
						else
						{
							label = labels[ (fx.dataset.motion === 'left') ? 0 : labels.length - 1 ];
							
							if(fx.dataset.motion === 'left')
							{
								pos = {
									left: label.getBoundingClientRect().left - group.getBoundingClientRect().left,
									right: Math.floor(group.getBoundingClientRect().right - label.getBoundingClientRect().right)
								};
							}
							
							else
							{
								pos = {
									left: Math.floor((label.getBoundingClientRect().left - group.getBoundingClientRect().left) + label.getBoundingClientRect().width),
									right: group.getBoundingClientRect().right - label.getBoundingClientRect().right
								};
							}
						}

						fx.style.left = `${pos.left}px`;
						fx.style.right = `${pos.right}px`;
					}
				});
			});
			
			let items = nav.querySelectorAll(`.${selector}-item[href]`);

			items.forEach(item => {
				if(!item.closest(`.${selector}-group`))
				{
					if(item.matches(`[href$='${event.regionId}']`))
					{
						item.dataset.current = true;
					}

					else
					{
						delete item.dataset.current;
					}
				}
			});
		});
	});
	
	window.do.when('#navigation').then(nav => {
		let selector = `${window.namespace}-nav__menu`;
		let items = nav.querySelectorAll(`.${selector}-item[href]`);
		
		items.forEach(item => {
			item.addEventListener('click', click => {
				click.preventDefault();
				
				let region = click.target.closest('[href]').getAttribute('href').split('/').pop();

				window.scrollToElement(region);
			});
		});
	});

	window.do.when('#navigation-trigger').then(trigger => {
		trigger.checked = false;

		window.addEventListener('breakpoint', breakpoint => {
			switch(breakpoint.breakpoint)
			{
				case 'md':
				case 'lg':
				case 'xl':
				case 'xx':
				{
					trigger.checked = false;

					let site = [
						document.documentElement,
						document.body
					];

					site.forEach(context => {
						context.style.overflowY = 'auto';
					});
				}
			}
		});

		trigger.addEventListener('change', setSiteOverflow);
	});
}