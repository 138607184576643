'use strict';

{
	(function()
	{
		if(typeof window.CustomEvent === "function")
		{
			return false;
		}

		function CustomEvent(event, params)
		{
			params = params || { 
				bubbles: false, 
				cancelable: false, 
				detail: null 
			};
			var evt = document.createEvent('CustomEvent');

			evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);

			return evt;
		}

		window.CustomEvent = CustomEvent;
	})();
	
	let colorSchemeEvent = new CustomEvent('colorscheme');
	let query = window.matchMedia('(prefers-color-scheme: dark)');

	let trackColorScheme = function(event) 
	{
		if(event && event.matches)
		{
			document.documentElement.setAttribute('data-color-scheme', 'dark');

			colorSchemeEvent.scheme = 'dark';
		}
		
		else
		{
			document.documentElement.setAttribute('data-color-scheme', 'light');

			colorSchemeEvent.scheme = 'light';
		}

		window.dispatchEvent(colorSchemeEvent);
	};

	trackColorScheme();
	
	try
	{
		query.addEventListener('change', trackColorScheme);
	}
	
	catch(err)
	{
		query.addListener('change', trackColorScheme);
	}
	
	window.setColorScheme = function(scheme) 
	{
		if(typeof scheme !== 'string')
		{
			throw new TypeError(`String expected, ${typeof scheme} given`);

			return false;
		}
		
		else
		{
			scheme = scheme.toLowerCase();

			if(!/^dark|light$/gi.test(scheme))
			{
				console.error(`Unsupported scheme '${scheme}. Only 'dark' and 'light' are supported values.`);
				
				document.documentElement.dataset.colorScheme = 'light';
			}
			
			else
			{
				document.documentElement.dataset.colorScheme = scheme;
			}
			
			colorSchemeEvent.scheme = scheme;

			window.dispatchEvent(colorSchemeEvent);
		}
	};
}