'use strict';

{
	(function()
	{
		if(typeof window.CustomEvent === "function")
		{
			return false;
		}

		function CustomEvent(event, params)
		{
			params = params || { 
				bubbles: false, 
				cancelable: false, 
				detail: null 
			};
			var evt = document.createEvent('CustomEvent');

			evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);

			return evt;
		}

		window.CustomEvent = CustomEvent;
	})();
	
	let reducedMotionEvent = new CustomEvent('reducedmotion');
	let query = window.matchMedia('(prefers-reduced-motion: reduce)');
	
	let trackReducedMotion = function(event) 
	{
		document.documentElement.dataset.reducedMotion = (event && event.matches) ? true : false;
		reducedMotionEvent.value = (event && event.matches) ? true : false;

		window.dispatchEvent(reducedMotionEvent);
	};

	trackReducedMotion();

	try
	{
		query.addEventListener('change', trackReducedMotion);
	}
	
	catch(err)
	{
		query.addListener('change', trackReducedMotion);
	}
	
	window.setReducedMotion = function(flag) 
	{
		document.documentElement.dataset.reducedMotion = (flag) ? true : false;

		reducedMotionEvent.value = flag;

		window.dispatchEvent(reducedMotionEvent);
	};
}