'use strict';

window.namespace = document.documentElement.getAttribute('data-namespace');

//- Base Imports
require('/src/scripts/imports.js');

//- Components
require('/src/components/brand/brand.js');
require('/src/components/button/button.js');
require('/src/components/checkcard/checkcard.js');
require('/src/components/dropdown/dropdown.js');
require('/src/components/error/error.js');
require('/src/components/footnotes/footnotes.js');
require('/src/components/radio/radio.js');
require('/src/components/reference/reference.js');
require('/src/components/textfield/textfield.js');

//- Includes
require('/src/includes/cookie-consent/cookie-consent.js');
require('/src/includes/navigation/navigation.js');
require('/src/includes/post-navigation/post-navigation.js');

//- Views
require('/src/views/local/local.js');
require('/src/views/home/home.js');

//- Router
require('/src/scripts/router.js');

//- Everything Else
{
	scroll_direction:
	{
		window.scrollPosition = 0;
		window.scrollDirection = 'none';
		
		document.addEventListener('scroll', scroll => {
			let ctx = window.pageYOffset || document.documentElement.scrollTop;

			window.scrollDirection = (ctx > window.scrollPosition) ? 'down' : 'up';
			window.scrollPosition = ctx <= 0 ? 0 : ctx;
		});
	}
	
	scroll_intersector:
	{
		(function()
		{
			if(typeof window.CustomEvent === "function")
			{
				return false;
			}

			function CustomEvent(event, params)
			{
				params = params || { 
					bubbles: false, 
					cancelable: false, 
					detail: null 
				};
				var evt = document.createEvent('CustomEvent');

				evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);

				return evt;
			}

			window.CustomEvent = CustomEvent;
		})();
		
		let regionEvent = new CustomEvent('regionchange');
		
		set_initial_region:
		{
			window.do.when(`.${window.namespace}-section`).then(region => {
				window.region = region.id;
			});
		}

		document.addEventListener('scroll', scroll => {
			let was = window.region;
			let selector = `${window.namespace}-section`;
			let sections = document.querySelectorAll(`.${selector}[role='region']`);

			for(sections.i = sections.length; sections.i-- > 0;)
			{
				let section = sections[sections.i];

				if(section.getBoundingClientRect().top <= Math.floor(window.innerHeight * .3334))
				{
					window.region = section.id;
					
					break;
				}
			}
			
			if((window.innerHeight + window.pageYOffset) >= document.body.offsetHeight)
			{
				window.region = sections[sections.length - 1].id;
			}
			
			if(window.region !== was)
			{
				regionEvent.regionId = window.region;

				window.dispatchEvent(regionEvent);
			}
		});
	}
	
	scroll_to_element:
	{
		window.scrollToElement = function(element = null)
		{
			element	= (typeof element === 'string') ? document.getElementById(element) : element;
			
			if(!element)
			{
				console.error(`scrollToElement: element ${element} not found.`);

				return;
			}

			let y = window.pageYOffset;
			y += element.getBoundingClientRect().top;

			if(document.querySelector(`#header.${window.namespace}-document__header--fixed`))
			{
				y -= document.getElementById('header').getBoundingClientRect().height;
			}

			if((!/\s?msie\s?/gi.test(document.documentElement.dataset.navigator)))
			{
				window.scrollTo({
					behavior: 'smooth', 
					top: y
				});
			}
			
			else
			{
				window.scrollTo(0, Math.floor(y))
			}

			return y;
		};
	}
}