'use strict';

{
	let trackOS = function()
	{
		let a = navigator.userAgent;
		let c = [];
		let t = function(p) 
		{
			let r = new RegExp(p, 'gi').test(a);

			return r;
		};

		switch(true)
		{
			// Google Android
			case t('Android'):
			{
				c.push('android');

				break;
			}

			// Google Chrome OS
			case t('CrOS aarch'):
			case t('CrOS arm'):
			case t('CrOS x86'):
			{
				c.push('android');

				break;
			}
			
			// Apple iOS
			case t('iPhone'):
			case t('iPad'):
			case t('iPod'):
			case t('iOS'):
			{
				c.push('ios');

				break;
			}

			// Linux
			case t('Linux'):
			{
				c.push('linux');
				
				switch(true)
				{
					case t('ubuntu'):
					{
						c.push('ubuntu');

						break;
					}
				}

				break;
			}

			// Apple OSX
			case t('Linux'):
			{
				c.push('linux');
				
				switch(true)
				{
					case t('ubuntu'):
					{
						c.push('ubuntu');

						break;
					}
				}

				break;
			}

			// Windows
			case t('Windows'):
			{
				c.push('windows');

				break;
			}

			default:
			{
				c.push('unknown');
				
				break;
			}
		}
		
		document.documentElement.dataset.os = c.join(' ');
	
		window.requestAnimationFrame(trackOS);
	};
	
	window.requestAnimationFrame(trackOS);
}