'use strict';

/**
 *	Array.some()
 *
 *	Polyfill for browsers that don't have native feature support for Array.some().
 *
 *	@author: Mozilla
 *	@documentation: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/some
 */
if(!Array.prototype.some)
{
	Array.prototype.some = function(fun, thisArg)
	{
		if(this == null)
		{
			throw new TypeError('Array.prototype.some called on null or undefined');
		}

		if(typeof fun !== 'function')
		{
			throw new TypeError();
		}

		var t = Object(this);
		var len = t.length >>> 0;

		for(var i = 0; i < len; i++)
		{
			if(i in t && fun.call(thisArg, t[i], i, t))
			{
				return true;
			}
		}

		return false;
	};
}