'use strict';

{
	let trackSupportsWEBP = () => 
	{
		let tester = new Image();

    tester.onload = tester.onerror = () => 
		{
			document.documentElement.dataset.supportsWebp = (tester.height == 2) ? true : false;
    };

    tester.src = 'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';
	};
	
	window.requestAnimationFrame(trackSupportsWEBP);
}