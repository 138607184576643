'use strict';

require('/src/regions/contact/scripts/routes.js');

{
	window.do.when('#contact').then(region => {
		region.api = {
			auth: (function(host)
			{
				switch(host.toLowerCase())
				{
					case 'uspsconnect.com': 
					case 'www.uspsconnect.com': 
					{
						return 'https://uspsconnect.com/api/v2/auth'
					}

					default:
					{
						return 'https://stage.usps-connect.mrmclient.com/api/v2/auth';

						break;
					}
				}
			})(location.hostname),
			response: (function(host)
			{
				switch(host.toLowerCase())
				{
					case 'uspsconnect.com': 
					case 'www.uspsconnect.com': 
					{
						return 'https://www.uspsrms.com/api/Response'
					}

					default:
					{
						return 'https://apptest.iwco.com/d1/uspsrms/api/Response';

						break;
					}
				}
			})(location.hostname),
		};

		region.form = region.querySelector(`.${window.namespace}-form`);

		region.contents = region.querySelectorAll(`.${window.namespace}-section__content`);
		region.contents.forEach(content => {
			content.transitioner = window.set.transitioner({
				displayValue: 'flex', 
				element: content, 
				hideMode: 'hidden', 
				visibleClass: `${window.namespace}-section__content--visible`
			}).then(context => {
				content.transitioner = context;
			});
		});

		region.formContents = region.querySelectorAll(`.${window.namespace}-form__content`);
		region.formContents.forEach(formContent => {
			formContent.transitioner = window.set.transitioner({
				displayValue: 'block', 
				element: formContent, 
				hideMode: 'hidden', 
				visibleClass: `${window.namespace}-form__content--visible`
			}).then(context => {
				formContent.transitioner = context;
			});
		});

		region.interactors = region.querySelectorAll('[data-form-content], [data-region-content]');
		region.interactors.forEach(interactor => {
			interactor.addEventListener('click', click => {
				click.preventDefault();

				let targetContent;
				let currentContent;

				if(click.target.dataset.formContent)
				{
					targetContent = click.target.closest('[data-form-content]').dataset.formContent;
					currentContent = region.querySelector(`.${window.namespace}-form__content--visible`);
					
					let form = currentContent.closest(`.${window.namespace}-form`);
					
					if(!click.target.dataset.bypassValidation)
					{
						currentContent.querySelectorAll([
							`.${window.namespace}-checkbox`, 
							`.${window.namespace}-checkcard`, 
							`.${window.namespace}-radio`, 
							`.${window.namespace}-dropdown`, 
							`.${window.namespace}-textfield`
						].join(',')).forEach(input => {
							input.validate();
						});
						
						if(currentContent.querySelector('[class*="--invalid"]'))
						{
							window.scrollToElement(region);

							return false;
						}
					}

					if(region.querySelector(`.${window.namespace}-form__content#${targetContent}`))
					{
						targetContent = region.querySelector(`.${window.namespace}-form__content#${targetContent}`);

						if(currentContent)
						{
							currentContent.transitioner.hide();
							
							window.do.when(currentContent.transitioner.isHidden()).then(context => {
								if(targetContent.getAttribute('data-form-title'))
								{
									form.querySelector(`.${window.namespace}-form__header [role='heading']`).textContent = targetContent.getAttribute('data-form-title');
								}

								window.scrollToElement(region);

								window.setTimeout(() => {
									targetContent.transitioner.show();
									
									window.setTimeout(() => {
										region.setHeight(targetContent.closest(`.${window.namespace}-section__content--visible`));
									}, 550);
								}, 550);
							});
						}
						
						else
						{
							if(targetContent.getAttribute('data-form-title'))
							{
								form.querySelector(`.${window.namespace}-form__header [role='heading']`).textContent = targetContent.getAttribute('data-form-title');
							}

							window.scrollToElement(region);

							window.setTimeout(() => {
								targetContent.transitioner.show();

								window.setTimeout(() => {
									region.setHeight(targetContent.closest(`.${window.namespace}-section__content--visible`));
								}, 550);
							}, 550);
						} 
					}
				}
					
				else
				{
					targetContent = click.target.closest('[data-region-content]').dataset.regionContent;
					currentContent = region.querySelector(`.${window.namespace}-section__content--visible`);
					
					if(region.querySelector(`.${window.namespace}-section__content#${targetContent}`))
					{
						targetContent = region.querySelector(`.${window.namespace}-section__content#${targetContent}`);
						
						if(currentContent)
						{
							currentContent.transitioner.hide();
							
							window.do.when(currentContent.transitioner.isHidden()).then(context => {
								window.setTimeout(() => {
									targetContent.transitioner.show();
									
									window.setTimeout(() => {
										region.setHeight(targetContent);
									}, 25);

									window.scrollToElement(region);
								}, 550);
							});
						}
						
						else
						{
							targetContent.transitioner.show();

							window.setTimeout(() => {
								region.setHeight(targetContent);
							}, 25);

							window.scrollToElement(region);
						}
					}
				}

				return false;
			});
		});
		
		region.form.addEventListener('submit', submit => {
			submit.preventDefault();
			
			let form = submit.target;
			let currentContent = form.querySelector(`.${window.namespace}-form__content--visible`);
					
			currentContent.querySelectorAll([
				`.${window.namespace}-checkbox`, 
				`.${window.namespace}-checkcard`, 
				`.${window.namespace}-radio`, 
				`.${window.namespace}-dropdown`, 
				`.${window.namespace}-textfield`
			].join(',')).forEach(input => {
				input.validate();
			});
			
			if(currentContent.querySelector('[class*="--invalid"]'))
			{
				window.scrollToElement(currentContent.querySelector('[class*="--invalid"]'));

				return false;
			}
			
			else
			{
				window.router.navigate('/contact/loading');

				axios
					.get(region.api.auth)
					.then(response => {
						switch(response.data.code)
						{
							case 1:
							{
								if(response.data.token)
								{
									//- Show the user the thank you page
									//- Since both success and failure states yield the thank you, we should show the user this straight away									
									let bearerToken = response.data.token;
									let formData = {
										macCode: '', 
										title: '', 
										firstName: '', 
										middleName: '', 
										lastName: '', 
										suffix: '', 
										companyName: '', 
										address1: '', 
										address2: '', 
										address3: '', 
										city: '', 
										state: '', 
										zip5: '', 
										zip4: '', 
										phone: '', 
										phoneExt: '', 
										email: '', 
										iomCode: '', 
										sendToSales: '', 
										source: 'USPSConnect', 
										optIn: false, 
										answers: []
									};
									
									Object.keys(formData).forEach(key => {
										if(form.querySelector(`[name='${key}']`))
										{
											let input = form.querySelector(`[name='${key}']`);
											
											if(input.type === 'checkbox')
											{
												formData[key] = (input.checked) ? true : false;
											}
											
											else
											{
												formData[key] = form.querySelector(`[name='${key}']`).value;
											}
										}
									
										if(key === 'phone')
										{
											formData[key] = formData[key].replace(/\D+/g, '');
										}
									
										if(key === 'answers')
										{
											form.querySelectorAll('[name^="QNA"]').forEach(input => {
												if(!/textfield/.test(input.getAttribute('data-native')))
												{
													if(input.checked)
													{
														formData.answers.push({
															answerId: parseInt(input.value), 
															answerText: null
														})
													}
												}
												
												else
												{
													formData.answers.push({
														answerId: parseInt(input.dataset.qnaAnswerId),
														answerText: input.value
													})
												}
											});
										}
									});

									capture_iom_mac:
									{
										if(window.iomCode)
										{
											formData.macCode = window.macCode;
											formData.iomCode = window.iomCode;
										}
									}
								
									if(/\-/gi.test(formData.zip5))
									{
										let zip = formData.zip5.split('-');

										formData.zip5 = zip[0];
										formData.zip4 = zip[1];
									}

									formData.transactionId = (() => {
										return Date.now().toString(32) + Math.random().toString(32);
									})();
									formData.entryTimestamp = new Date().toISOString().replace(/T/gi, ' ').replace(/\..+$/gi, '');

									axios
										.post(region.api.response, formData, {
											headers: {
												'Authorization': `Bearer ${bearerToken}`
											}
										})
										.then(remoteResponse => {
											window.router.navigate('/contact/thankyou');
										})
										.catch(error => {
											window.router.navigate('/contact/error');
										});
								}
								
								else
								{
									window.router.navigate('/contact/error');
								}

								break;
							}
							
							default:
							{
								window.router.navigate('/contact/error');
							}
						}
					})
					.catch(error => {
						if(error.response)
						{
							console.warn(error.response.data.message);
						}
					});
			}

			return false;
		});
	});
	
	window.addEventListener('resize', resize => {
		let region = document.getElementById('contact');

		if(region)
		{
			let contentContext = region.querySelector(`.${window.namespace}-section__content--visible`);
			
			if(contentContext)
			{
				region.setHeight(contentContext);
			}
		}
	});
}