'use strict';

{
	let trackOrientation = function(event)
	{
		if(window.innerHeight >= window.innerWidth)
		{
			document.documentElement.dataset.orientation = 'portrait';
		}

		else
		{
			document.documentElement.dataset.orientation = 'landscape';
		}
	};

	[
		'resize', 
		'orientationchange', 
		'msorientationchange'
	].forEach(function(eventListener)
	{
		window.addEventListener(eventListener, trackOrientation);
	});

	trackOrientation();
}