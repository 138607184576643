'use strict';

/**
 *	Array.forEach()
 *
 *  Polyfill for browsers that don't have native feature support for Array.forEach().
 *
 *	@author: Mozilla
 *	@documentation: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/forEach
 */
	if(!Array.prototype.forEach) Array.prototype.forEach = function(callback)
	{
		var T, i;

		if(this == null) throw new TypeError("this is null or not defined");

		var Obj = Object(this);
		var len = Obj.length >>> 0;

		if(typeof callback !== "function")
		{
			throw new TypeError(callback + " is not a function");
		}

		if(arguments.length > 1)
		{
			T = arguments[1];
		}

		i = 0;

		while(i < len)
		{
			var iValue;

			if(i in Obj)
			{
				iValue = Obj[i];

				callback.call(T, iValue, i, Obj);
			}

			i++;
		}
	};