'use strict';

/**
 *	Element.matches()
 *
 *  Polyfill for browsers that don't have native feature support for Element.matches().
 *
 *	@author: Mozilla
 *	@documentation: https://developer.mozilla.org/en-US/docs/Web/API/Element/closest
 */
	if(!Element.prototype.matches) Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;