'use strict';

{
	let trackReducedData = () => 
	{
		let c = false;

		if(navigator.connection)
		{
			try
			{
				c = navigator.connection.saveData;
			}
			
			finally{}
		}

		document.documentElement.dataset.reducedData = c;
	
		window.requestAnimationFrame(trackReducedData);
	};
	
	window.requestAnimationFrame(trackReducedData);
}