'use strict';

/**
 *	NodeList.some()
 *
 *  Polyfill for browsers that don't have native feature support for NodeList.some().
 */
	if(typeof NodeList.prototype.some !== "function")
	{
		NodeList.prototype.some = Array.prototype.some;
	}