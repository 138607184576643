'use strict';

window.router
	.always(context => {
		set_iom_mac:
		{
			let query = window.querystring.parse(location.search);

			if(query.iom)
			{
				window.macCode = query.iom.split('-')[0];
				window.iomCode = query.iom;
			}
		}
	});