'use strict';

//- Bridges
	import 'core-js/stable';
	import 'regenerator-runtime/runtime';
	import 'promise-polyfill/src/polyfill';
	import 'wicg-inert';

//- Polyfills
	require('/src/scripts/polyfills/array.foreach.js');
	require('/src/scripts/polyfills/array.some.js');
	require('/src/scripts/polyfills/childnode.remove.js');
	require('/src/scripts/polyfills/element.closest.js');
	require('/src/scripts/polyfills/element.matches.js');
	require('/src/scripts/polyfills/nodelist.foreach.js');
	require('/src/scripts/polyfills/nodelist.some.js');
	require('/src/scripts/polyfills/string.includes.js');
	require('/src/scripts/polyfills/window.scroll.js');

//- Plugins
	window.axios = require('axios');
	window.router = require('routerjs').createRouter({
		ignoreCase: true
	});
	window.querystring = require('/src/scripts/vendor/query-string/query-string.js');

//- Turnips
	window.do = {
		always: require('/src/scripts/turnips/do.always.js').always,
		when: require('/src/scripts/turnips/do.when.js').when
	};

	window.set = {
		transitioner: require('/src/scripts/vendor/@cloudfour/transition-hidden-element.js').transitionHiddenElement
	};

//- Evergreens
	require('/src/scripts/qol/live.breakpoint.js');
	require('/src/scripts/qol/live.color-scheme.js');
	require('/src/scripts/qol/live.javascript-enabled.js');
	require('/src/scripts/qol/live.orientation.js');
	require('/src/scripts/qol/live.os.js');
	require('/src/scripts/qol/live.preferred-language.js');
	require('/src/scripts/qol/live.reduced-data.js');
	require('/src/scripts/qol/live.reduced-motion.js');
	require('/src/scripts/qol/live.vendor.js');
	require('/src/scripts/qol/static.animated-svg.js');
	require('/src/scripts/qol/static.webp.js');

//- Components
	require('/src/components/checkbox/checkbox.js');
	require('/src/components/checkcard/checkcard.js');
	require('/src/components/dropdown/dropdown.js');
	require('/src/components/radio/radio.js');
	require('/src/components/textfield/textfield.js');

//- Regions
	require('/src/regions/contact/contact.js');