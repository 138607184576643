'use strict';

{
	let consent = localStorage.cookieconsent;

	if(!consent || consent !== '1')
	{
		window.do.when('#cookie-consent').then(overlay => {
			window.setTimeout(() => {
				overlay.dataset.ready = true;
			}, 100);
			
			overlay.querySelector('button').addEventListener('click', click => {
				click.preventDefault();

				localStorage.cookieconsent = '1';

				if(document.activeElement)
				{
					document.activeElement.blur();
				}

				delete overlay.dataset.ready;

				return false;
			});
		});
	}
}