'use strict';

{	
	let classname = `${window.namespace}-textfield`;
	
	window.do.always(() => {
		let selector = `${classname}:not([data-assigned])`;

		if(document.querySelectorAll(`.${selector}`).length)
		{
			document.querySelectorAll(`.${selector}`).forEach(textfield => 
			{
				textfield.validate = function()
				{
					let input = this.querySelector(`.${classname}__native`);
					let error = input.closest(`.${classname}`).querySelector(`.${window.namespace}-error`);

					if(!input.required)
					{
						textfield.classList.remove(`${classname}--invalid`);

						return true;
					}

					let pattern = (input.pattern) ? input.pattern : '^.+$';
					pattern = new RegExp(pattern, 'gi');

					if(!pattern.test(input.value))
					{
						switch(input.dataset.native.toLowerCase())
						{
							case 'email':
							{
								error.textContent = (input.value !== '') ? 
									'Please enter a complete email address, such as example@email.com.' : 
									'Please enter an email address.';
								
								break;
							}
						}
						
						textfield.classList.add(`${classname}--invalid`);
					}
					
					else
					{
						textfield.classList.remove(`${classname}--invalid`);
					}
				};

				{
					let input = textfield.querySelector(`.${classname}__native`);
					
					if(input.value && input.value !== '')
					{
						input.classList.add(`${classname}__native--filled`);
					}
					
					[
						'input',
						'blur'
					].forEach(eventListener => {
						input.addEventListener(eventListener, (event) => {
							if(input.value && input.value !== '')
							{
								textfield.classList.add(`${classname}--dirty`);
								input.classList.add(`${classname}__native--filled`);
							}
							
							else
							{
								input.classList.remove(`${classname}__native--filled`);
							}

							switch(input.dataset.native.toLowerCase())
							{
								case 'area': 
								{
									input.value = input.value.replace(/[\r\n]/gi, " ");
								}

								case 'email':
								{
									break;
								}

								case 'phone':
								{
									input.value = input.value.replace(/[^\d]/gi, "");

									switch(Boolean(true))
									{
										case input.value.length <= 3:
										{
											input.value = input.value.replace(/^(\d{1,3})/, "$1");

											break;
										}

										case input.value.length >= 4 && input.value.length <= 6:
										{
											input.value = input.value.replace(/^(\d{1,3})(\d{1,3})/, "($1) $2");

											break;
										}

										case input.value.length >= 7:
										{
											input.value = input.value.replace(/^(\d{1,3})(\d{1,3})(\d{1,4})/, "($1) $2-$3");

											break;
										}
									}
									
									break;
								}

								case 'zip':
								{
									input.value = input.value.replace(/[^\d|-]/gi, "");

									if(input.value.length >= 6 && !/^[0-9]{5}\-/gi.test(input.value))
									{
										input.value = input.value.replace(/^([0-9]{5})/gi, "$1-");
									}

									else if(input.value.length <= 6 && /^[0-9]{5}\-/gi.test(input.value))
									{
										input.value = input.value.replace(/^([0-9]{5})\-/gi, "$1");
									}
									
									break;
								}
							}

							if(/\-\-invalid\s?/gi.test(textfield.getAttribute('class')))
							{
								textfield.validate();
							}
						});
					});
				}
				
				textfield.dataset.assigned = `${window.namespace}`;
			});
		}
	});
}