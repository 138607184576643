'use strict';

{
	let trackSupportsAnimatedSVG = () => 
	{
		if(navigator.userAgent.indexOf('MSIE') || navigator.appVersion.indexOf('Trident/'))
		{
			document.documentElement.dataset.supportsAnimatedSvg = false;
		}
		
		else
		{
			document.documentElement.dataset.supportsAnimatedSvg = true;
		}
	};
	
	window.requestAnimationFrame(trackSupportsAnimatedSVG);
}