'use strict';

window.router
	.get('/:section/:display?', (req, context) => {
		window.do.when(`#${req.params.section}`).then(region => {
			let scrollTarget = region.getBoundingClientRect().top + window.pageYOffset;

			if(region.querySelector(`.${window.namespace}-section__content`) && /--offset/gi.test(region.querySelector(`.${window.namespace}-section__content`).getAttribute('class')))
			{
				let content = region.querySelector(`.${window.namespace}-section__content--offset`);
				let offset = Math.abs(parseInt(window.getComputedStyle(content).getPropertyValue('margin-top')));

				scrollTarget -= offset * 2;
			}

			if(document.querySelector(`#header.${window.namespace}-document__header--fixed`))
			{
				let header = document.getElementById('header');
				
				scrollTarget -= header.getBoundingClientRect().height;
			}

			window.setTimeout(() => {
				if((!/\s?msie\s?/gi.test(document.documentElement.dataset.navigator)))
				{
					window.scrollTo({
						behavior: 'smooth', 
						top: scrollTarget
					});
				}

				else
				{
					window.scrollTo(0, Math.floor(scrollTarget))
				}
			});
		});
	});