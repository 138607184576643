'use strict';

{
	let regionId = 'how-it-works';

	let bindView = function(req,context)
	{
		window.do.when(`#${regionId}`).then(region => {
			region.menu = region.querySelector(`.${window.namespace}-hiw__menu`);
			region.displays = region.querySelectorAll(`.${window.namespace}-hiw__display`);

			region.displays.forEach(display => {
				display.transitioner = window.set.transitioner({
					displayValue: 'block',
					element: display,
					hideMode: 'hidden',
					visibleClass: `${window.namespace}-hiw__display--visible`
				}).then(context => {
					display.transitioner = context;
				});
			});

			window.do.when(region.querySelector(`.${window.namespace}-hiw__display`).transitioner).then(ctx => {
				region.updateDisplay = region.updateDisplay || function(id = 'receive')
				{
					region.querySelectorAll(`.${window.namespace}-hiw__display[id]`).forEach(display => {
						if(display.id === id)
						{
							if(display.transitioner.isHidden())
							{
								display.transitioner.show();
							}

							return;
						}

						if(!display.transitioner.isHidden())
						{
							display.transitioner.hide();
						}
					});
				};

				region.updateDisplay(window.router.req.params.display);
			});

			region_menu_functionality:
			{
				let menuItems = region.querySelectorAll(`.${window.namespace}-hiw__menu-item`);
				
				region.menu.updateState = function(id)
				{
					if(!id)
					{
						id = region.querySelector(`.${window.namespace}-hiw__display[id]`).id;
					}
					
					menuItems.forEach(item => {
						let classname = item.getAttribute('class').split(' ')[0];
						let itemId = item.getAttribute('href').split('/').pop();
						
						if(itemId === id)
						{
							item.classList.add(`${classname}--active`);
						}
						
						else
						{
							item.classList.remove(`${classname}--active`);
						}
					});
				};
				
				region.menu.updateState(window.router.req.params.display);

				menuItems.forEach(item => {
					item.addEventListener('click', click => {
						click.preventDefault();

						let targetItem = click.target.closest('[href]');
						let id = targetItem.closest('[href]').getAttribute('href').split('/').pop();
						let href = targetItem.getAttribute('href');
						
						region.menu.updateState(id);
						region.updateDisplay(id);
						window.router.setLocation(href);

						return false;
					});
				});
			}
		});
	};

	window.router
		.get(`/${regionId}/:display?`, (req, context) => {
			window.router.req = req;
			window.router.context = context;

			window.do.when(`#${regionId}`).then(region => {
				bindView(req, context);
			});
		});
}