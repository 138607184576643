'use strict';

{
	let regionId = 'explore';

	window.do.when(`#${regionId}`).then(region => {
		let menuItems = region.querySelectorAll('[data-choose-product]');

		menuItems.forEach(item => {
			item.addEventListener('click', function(click)
			{
				click.preventDefault();
				
				menuItems.forEach(function(menuItem)
				{
					let classname = menuItem.getAttribute('class').split(' ')[0];

					if(menuItem === item)
					{
						menuItem.classList.add(`${classname}--selected`);
					}
					
					else
					{
						menuItem.classList.remove(`${classname}--selected`);
					}
				});

				let product = this.dataset.chooseProduct;
				let featureList = region.querySelectorAll('[data-for-products]');

				featureList.forEach(function(feature)
				{
					let classname = feature.getAttribute('class').split(' ')[0];
					
					if(feature.matches(`[data-for-products~='${product}']`))
					{
						feature.classList.add(`${classname}--supported`);
					}

					else
					{
						feature.classList.remove(`${classname}--supported`);
					}
				});
				
				return false;
			});
		});
	});
}